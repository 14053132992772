import React from 'react';
import s from './CartItem.scss';
import {CartItemThumbnail} from '../CartItemThumbnail/CartItemThumbnail';
import {Name} from './partials/Name/Name';
import {Options} from './partials/Options/Options';
import {Price} from './partials/Price/Price';
import {Remove} from './partials/Remove/Remove';
import {ICartItem} from '../../../../../../types/app.types';
import {Quantity} from './partials/Quantity/Quantity';
import {TotalPrice} from './partials/TotalPrice/TotalPrice';
import {OutOfStock} from './partials/OutOfStock/OutOfStock';
import {hasFreeText, hasOptions} from '../../../../../../domain/utils/itemUtils';

export enum CartItemDataHook {
  'ComparePrice' = 'CartItemDataHook.comparePrice',
  'Name' = 'CartItemDataHook.name',
  'Options' = 'CartItemDataHook.options',
  'FreeText' = 'CartItemDataHook.freeText',
  'Price' = 'CartItemDataHook.price',
  'Quantity' = 'CartItemDataHook.quantity',
  'QuantityErrorTooltip' = 'CartItemDataHook.quantityErrorTooltip',
  'OutOfStockRoot' = 'CartItemDataHook.outOfStockRoot',
  'OutOfStockError' = 'CartItemDataHook.outOfStockError',
  'Remove' = 'CartItemDataHook.remove',
  'TotalPrice' = 'CartItemDataHook.totalPrice',
  'ProductLineItem' = 'CartItemDataHook.productLineItem',
}

export const CartItem = ({
  item,
  isSupportOnlyTitleOptionEnabled,
}: {
  item: ICartItem;
  isSupportOnlyTitleOptionEnabled: boolean;
}) => {
  const isItemOutOfStock = item.inventoryQuantity === 0;

  return (
    <div className={s.itemWrapper}>
      <div className={s.item}>
        <div className={s.info}>
          <CartItemThumbnail item={item} />
          <div className={s.details}>
            <div className={s.content}>
              <Name item={item} />
              <Price item={item} />
              {hasOptions(item) && (
                <Options
                  itemId={item.cartItemId}
                  options={item.optionsSelectionsValues}
                  dataHook={CartItemDataHook.Options}
                  isSupportOnlyTitleOptionEnabled={isSupportOnlyTitleOptionEnabled}
                />
              )}
              {hasFreeText(item) && (
                <Options itemId={item.cartItemId} options={item.freeText} dataHook={CartItemDataHook.FreeText} />
              )}
            </div>
            <Quantity item={item} key={`item-${item.cartItemId}-${item.quantity}`} />
          </div>
        </div>
        <div className={s.colEnd}>
          <TotalPrice item={item} />
          <Remove item={item} />
        </div>
      </div>
      {isItemOutOfStock && <OutOfStock />}
    </div>
  );
};
